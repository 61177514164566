import React, { useState } from 'react';
import SocialBtns from './SocialBtns';
import ContactInfo from './ContactInfo';
import { useTranslation } from 'react-i18next'; // Importar useTranslation

export default function Contact({ data, socialData }) {
  const { t } = useTranslation(); // Hook de traducción
  const { sectionHeading, contactImg, contactInfo } = data;

  const [formData, setFormData] = useState({
    name: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleMailto = (e) => {
    e.preventDefault();
    const subject = `Message from ${formData.name}: ${formData.subject}`;
    const body = `${formData.message}`;
    const mailtoLink = `mailto:maxmateo.carrion@gmail.com?subject=${encodeURIComponent(subject.trim())}&body=${encodeURIComponent(body.trim())}`;
    window.location.href = mailtoLink;
  };

  return (
    <section id="contactus" className="section contactus-section">
      <div className="container">
        <div className="contactus-box rounded overflow-hidden gray-bg">
          <div className="row g-0 p-4 p-lg-5">
            <div className="col-lg-4" />
            <div className="col-lg-8">
              <div
                className="contactus-title"
                data-aos="fade-left"
                data-aos-duration="1200"
                data-aos-delay="200"
              >
                <h5>{t('contact.title')}</h5> {/* Traducción del título */}
                <p className="m-0">{t('contact.subTitle')}</p> {/* Traducción del subtítulo */}
              </div>
            </div>
          </div>
          <div className="row g-0 contactus-form p-4 p-lg-5 flex-row-reverse">
            <div className="col-lg-8">
              <div className="contact-form">
                <form onSubmit={handleMailto}>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      {t('contact.form.name')} {/* Traducción del campo nombre */}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="subject" className="form-label">
                      {t('contact.form.subject')} {/* Traducción del campo asunto */}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="subject"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="message" className="form-label">
                      {t('contact.form.message')} {/* Traducción del campo mensaje */}
                    </label>
                    <textarea
                      className="form-control"
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows="5"
                      required
                    ></textarea>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    {t('contact.form.send')} {/* Traducción del botón enviar */}
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-4 pe-md-5">
              <div className="contact-banner d-none d-lg-block">
                <img src={contactImg} title alt="Avatar" />
              </div>
              <ContactInfo contactInfoData={contactInfo} />
              <SocialBtns socialBtns={socialData} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
