import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { FaGlobe } from 'react-icons/fa'; // Importamos el ícono de idioma
import { useNavigate } from 'react-router-dom'; // Usar useNavigate para redireccionar sin recargar

export default function Header() {
  const { t, i18n } = useTranslation();
  const [mobileToggle, setMobileToggle] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate(); // Hook para redirigir sin recargar la página

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'es' : 'en';
    i18n.changeLanguage(newLang);
    navigate(`/${newLang}/home`); // Redirige usando React Router sin recargar la página
  };

  return (
    <div
      className={`header-top-fixed one-page-nav ${
        mobileToggle ? 'menu-open menu-open-desk' : ''
      } ${scrolled ? 'fixed-header' : ''}`}
    >
      <div className="container">
        <div className="logo">
          <Link
            className="navbar-brand"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            <img
              className="logo-light"
              title="Logo"
              alt="Logo"
              src="/images/logo-light.svg"
            />
          </Link>
        </div>
        <ul className="main-menu">
          <li>
            <ScrollLink
              to="home"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              onClick={() => setMobileToggle(false)}
            >
              {t('header.home')}
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              to="about"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              onClick={() => setMobileToggle(false)}
            >
              {t('header.about')}
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              to="project"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              onClick={() => setMobileToggle(false)}
            >
              {t('header.projects')}
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              to="Certifications"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              onClick={() => setMobileToggle(false)}
            >
              {t('header.certifications')}
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              to="contactus"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              onClick={() => setMobileToggle(false)}
            >
              {t('header.contact')}
            </ScrollLink>
          </li>
        </ul>
        <div className="d-flex items-center">
      {/* Botón para cambiar de idioma */}
      <button
        onClick={toggleLanguage}
        style={{
          padding: '5px 10px',       // Reducimos el padding
          fontSize: '15px',          // Tamaño de fuente pequeño
          borderRadius: '20px',       // Redondeamos un poco las esquinas
          backgroundColor: '#007bff', // Color de fondo (puedes ajustarlo)
          color: 'white',            // Color del texto
          display: 'flex',           // Flexbox para alinear ícono y texto
          alignItems: 'center',      // Alinear ícono y texto verticalmente
          border: 'none',            // Eliminar el borde
          cursor: 'pointer',         // Cursor de puntero
        }}
      >
        <FaGlobe style={{ fontSize: '1rem', marginRight: '5px' }} /> {/* Ícono más pequeño */}
        <span>{i18n.language === 'en' ? 'English' : 'Español'}</span>
      </button>
    </div>
    <button
      className="toggler-menu d-lg-none"
      onClick={() => setMobileToggle(!mobileToggle)}
    >
      <span />
    </button>

      </div>
    </div>
  );
}
