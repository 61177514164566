import React from 'react';
import Slider from 'react-slick';
import SectionHeading from './SectionHeading';

export default function Service({ data }) {
  const { sectionHeading, allService } = data;

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 4, 
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="section" id="Certifications">
      <div className="container">
        <SectionHeading
          miniTitle={sectionHeading.miniTitle}
          title={sectionHeading.title}
        />
        <Slider {...settings} className="slider-gap-50">
          {allService?.map((item, index) => (
            <div key={index} className="col-sm-6 col-lg-3">
              <div
                className="services-box"
                style={{ backgroundImage: `url(${item.imgUrl})` }}
                data-aos="fade-left"
                data-aos-duration="1200"
                data-aos-delay={index * 100}
              >
                <div className="services-body">
                  <div className="icon">
                    <img src={item.icon} alt={item.title} style={{ width: '50px', height: '50px' }} />
                  </div>
                  <h5>{item.title}</h5>
                  <p>{item.subTitle}</p>
                  <div className="rating-wrap">
                    {/* <Ratings ratings={item.ratings} /> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}
