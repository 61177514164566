import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaExclamationTriangle } from 'react-icons/fa'; // Importamos el ícono de advertencia

export default function NotFound() {
  const navigate = useNavigate();

  useEffect(() => {
  
    const timer = setTimeout(() => {
      navigate('/home');
    }, 3000);

    return () => clearTimeout(timer); 
  }, [navigate]);

  return (
    <div className="not-found-page" style={styles.container}>
      <div style={styles.content}>
        
        <FaExclamationTriangle style={styles.icon} />
        <h1 style={styles.title}>404 - Page Not Found</h1>
        <p style={styles.text}>The page you're looking for doesn't exist. You will be redirected in a few seconds...</p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    padding: '0 20px',
  },
  content: {
    maxWidth: '600px',
  },
  icon: {
    fontSize: '5rem',
    color: '#ffcc00', 
    marginBottom: '1rem',
  },
  title: {
    fontSize: '3rem',
    color: '#fff',
    marginBottom: '1rem',
  },
  text: {
    fontSize: '1.25rem',
    color: '#ccc',
  },
};
