import React from 'react';
import SectionHeading from './SectionHeading';

export default function Testimonial({ data }) {
  const { sectionHeading = {} } = data;
  const { miniTitle = '', title = '', subTitle = '' } = sectionHeading;

  return (
    <section className="section effect-section pb-0">
      <div className="effect-3">
        <img src="/images/effect-3.svg" title alt="" />
      </div>
      <div className="effect-4">
        <img src="/images/effect-4.svg" title alt="" />
      </div>
      <div className="container text-center">
        <SectionHeading
          miniTitle={miniTitle}
          title={title}
          variant="text-center"
        />
        <p className="lead">{subTitle}</p>
      </div>
    </section>
  );
}
