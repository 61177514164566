import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import SectionHeading from './SectionHeading';
import Slider from 'react-slick';
import Modal from './Modal';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

export default function Projects({ data }) {
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState('image');
  const [modalData, setModalData] = useState({});
  const { sectionHeading, allProjects } = data;
  
  const handelProjectDetails = (item, itemType) => {
    setModalData(item);
    setModalType(itemType);
    setModal(!modal);
  };

  var projectSliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000, // Aumenta el tiempo entre cada slide (de 3000ms a 5000ms)
    speed: 1500, // Aumenta el tiempo de transición entre slides (de 1000ms a 1500ms)
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  var mainSliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    variableWidth: true,
    draggable: true, // Habilita el desplazamiento con el mouse
  };

  return (
    <>
      <section className="project-section section gray-bg" id="project">
        <div className="container">
          <SectionHeading
            miniTitle={sectionHeading.miniTitle}
            title={sectionHeading.title}
          />
          <div
            className="full-width"
            data-aos="fade"
            data-aos-duration="1200"
            data-aos-delay="400"
          >
            <Slider {...mainSliderSettings} className="slider-gap-24">
              {allProjects?.map((item, index) => (
                <div
                  key={index}
                  style={{ width: '416px' }}
                >
                  <div className="project-box">
                    <div className="project-media">
                      <Slider {...projectSliderSettings}>
                        {item.thumbUrls.map((url, i) => (
                          <img
                            key={i}
                            className="border lazyload"
                            data-src={url}
                            alt={item.title}
                            src="/path/to/placeholder.jpg" // Ruta a una imagen placeholder
                          />
                        ))}
                      </Slider>
                      <span className="gallery-link" onClick={() => handelProjectDetails(item, 'details')}>
                      <i>
                        <Icon icon="bi:plus" />
                      </i>
                    </span>
                    </div>
                    <div className="project-body">
                      <div className="text">
                        <h5>{item.title}</h5>
                        <span>{item.subTitle}</span>
                      </div>
                      <div className="link">
                        <span className="p-link" onClick={() => handelProjectDetails(item, 'details')}>
                          <Icon icon="bi:arrow-right" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
      {modal && (
        <div className="mfp-wrap">
          <div className="mfp-container">
            <div className="mfp-bg" onClick={() => setModal(!modal)}></div>
            <div className="mfp-content">
              <button
                type="button"
                className="mfp-close"
                onClick={() => setModal(!modal)}
              >
                ×
              </button>
              {modalType === 'image' ? (
                <img src={modalData.thumbUrls[0]} alt="Thumbnail" />
              ) : (
                <Modal modalData={modalData} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}