import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/Header';
import NotFound from './pages/NotFound'; 
import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    // Detecta el idioma desde la URL
    const lang = location.pathname.split('/')[1];
    if (lang && (lang === 'en' || lang === 'es')) {
      i18n.changeLanguage(lang);
    } else {
      // Si no hay idioma en la URL, redirige a /en por defecto
      i18n.changeLanguage('en');
    }
  }, [location, i18n]);

  return (
    <>
      <Header />
      <Routes>
        {/* Redirigir al idioma predeterminado según la ruta */}
        <Route path="/" element={<Navigate to={`/${i18n.language}/home`} />} />

        {/* Rutas para inglés */}
        <Route path="/en/home" element={<Home />} />
        <Route path="/en" element={<Navigate to="/en/home" />} /> {/* Redirige /en a /en/home */}

        {/* Rutas para español */}
        <Route path="/es/home" element={<Home />} />
        <Route path="/es" element={<Navigate to="/es/home" />} /> {/* Redirige /es a /es/home */}

        {/* Redirigir /home a /en/home */}
        <Route path="/home" element={<Navigate to="/en/home" />} />

        {/* Página 404 */}
        <Route path="*" element={<NotFound />} /> {/* Ruta para cualquier URL no válida */}
      </Routes>
    </>
  );
}

export default App;
