import React from 'react';
import parser from 'html-react-parser';
import Slider from 'react-slick';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import { useTranslation } from 'react-i18next';

export default function Modal({ modalData }) {
  const { t } = useTranslation(); // Hook de traducción
  const { thumbUrls, details } = modalData;
  const { title, description, type, languages, platform, country, url } = details;

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="px-modal">
      <div className="single-project-box">
        <div className="row align-items-start">
          <div className="col-lg-7">
            {thumbUrls && (
              <Slider {...sliderSettings}>
                {thumbUrls.map((imageUrl, index) => (
                  <img
                    key={index}
                    className="border lazyload"
                    data-src={imageUrl}
                    alt={`Thumbnail ${index + 1}`}
                    src="/path/to/placeholder.jpg" 
                  />
                ))}
              </Slider>
            )}
          </div>
          <div className="col-lg-5 pt-4 pt-lg-0">
            {title && <h4>{parser(title)}</h4>}
            {description && <p>{parser(description)}</p>}
            <div className="about-content">
              <ul>
                {type && (
                  <li className="d-flex">
                    <span className="col-4 col-lg-3">{t('modal.type')}:</span>
                    <span>{type}</span>
                  </li>
                )}
                {languages && (
                  <li className="d-flex">
                    <span className="col-4 col-lg-3">{t('modal.languages')}:</span>
                    <span>{languages}</span>
                  </li>
                )}
                {platform && (
                  <li className="d-flex">
                    <span className="col-4 col-lg-3">{t('modal.platform')}:</span>
                    <span>{platform}</span>
                  </li>
                )}
                {country && (
                  <li className="d-flex">
                    <span className="col-4 col-lg-3">{t('modal.country')}:</span>
                    <span>{country}</span>
                  </li>
                )}
                {url && (
                  <li className="d-flex">
                    <span className="col-4 col-lg-3">{t('modal.liveUrl')}:</span>
                    <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
