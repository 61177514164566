import React from 'react';
import Brands from '../components/Brands';
import Projects from '../components/Projects';
import Testimonial from '../components/Testimonial';
import Hero from '../components/Hero';
import About from '../components/About';
import Service from '../components/Service';
import Experience from '../components/Experience';
import Header from '../components/Header';
import Contact from '../components/Contact';
import { useTranslation } from 'react-i18next'; 

export default function Home() {
  const { t } = useTranslation(); 

  const hero = t('hero', { returnObjects: true });
  const socialBtns = t('socialBtns', { returnObjects: true });
  const brands = t('brands', { returnObjects: true });
  const about = t('about', { returnObjects: true });
  const projects = t('projects', { returnObjects: true });
  const service = t('service', { returnObjects: true });
  const experience = t('experience', { returnObjects: true });
  const testimonial = t('testimonial', { returnObjects: true });
  const contact = t('contact', { returnObjects: true });

  return (
    <>
      <Header />
      <Hero data={hero} socialData={socialBtns} />
      <Brands data={brands} />
      <About data={about} />
      <Projects data={projects} />
      <Service data={service} />
      <Experience data={experience} />
      <Testimonial data={testimonial} />
      <Contact data={contact} socialData={socialBtns} />
    </>
  );
}
